import {atoms, Stack} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {EmployeeService, ProfilePage} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ProfileBadges} from 'components/profile/ProfileBadges';
import {ProfileBody} from 'components/profile/ProfileBody';
import {ProfileEmployees} from 'components/profile/ProfileEmployees';
import {ProfileGoals} from 'components/profile/ProfileGoals';
import {ProfileProjects} from 'components/profile/ProfileProjects';
import {ProfileReportingTree} from 'components/profile/ProfileReportingTree';
import {ProfileWorkingWithMe} from 'components/profile/ProfileWorkingWithMe';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {OrgChart} from 'pages/OrgChart/Chart';
import {useEffect, useState} from 'react';
import {useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

interface ProfileViewProps {
  user?: string;
}

export const ProfileView = ({user}: ProfileViewProps) => {
  const props = useLoaderData() as ProfilePage;
  const location = useLocation();
  const navigate = useNavigate();
  const {isOrgChartEnabled} = useAtomValue(growthbookCacheAtom);
  const [expanded, setExpanded] = useState(false);
  useDocumentTitle(props?.employee.name ?? '');

  const {data: tree} = useQuery({
    queryKey: ['people', 'tree', props.employee.ldap!],
    queryFn: () => getService(EmployeeService).getTreeApiV1PeopleLdapTreeGet(props.employee.ldap!),
    enabled: !!props.employee?.ldap && isOrgChartEnabled,
  });
  const [selection, setSelection] = useState<string | undefined>();
  const [focus, setFocus] = useState<string>(props.employee.ldap);

  useEffect(() => {
    setFocus(props.employee.ldap);
  }, [props.employee.ldap]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (props.employee) {
      analyticsLogger().logEvent('PROFILE_OPENED', {
        level: props.employee.level ?? undefined,
        source: location.state?.source,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.employee]);

  if (!user || !props || !props.employee) {
    return <NotFound />;
  }

  const directReportLdaps = props.directReports.map((employee) => employee.ldap);
  const peers = (props.teamMembers || []).filter((teamMember) => {
    const isManager = teamMember.user_id === props.employee.manager_id;
    const isDirectReport = directReportLdaps.indexOf(teamMember.ldap) !== -1;
    // Peer cannot be user's direct report or manager
    return !isManager && !isDirectReport;
  });

  // const profileActionLog = (action: string) =>
  //   analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  // TODO: Add loading state
  return (
    <Layout.Container size="large">
      <Stack gap="24">
        <Stack
          gap="24"
          className={atoms({opacity: expanded ? 0 : 1})}
          style={{
            transition: 'opacity var(--easing__linear) var(--duration__non-motion)',
          }}
        >
          <ProfileBody
            employee={props.employee}
            employeeTeams={props.employeeTeams}
            admin={props.admin}
            disableConfetti
          />
          {!isOrgChartEnabled && (
            <>
              <ProfileProjects employee={props.employee} />
              <ProfileGoals employee={props.employee} />
            </>
          )}
        </Stack>

        {!isOrgChartEnabled && (
          <>
            {props.reportingLine && props.reportingLine.length > 1 && (
              <ProfileReportingTree reportingLine={props.reportingLine} />
            )}
            {props.directReports && props.directReports.length > 0 && (
              <ProfileEmployees
                sectionTitle={t('reports')}
                employees={props.directReports}
                employee={props.employee}
                source="reports"
              />
            )}
          </>
        )}

        {isOrgChartEnabled && (
          <OrgChart
            data={tree}
            toggle={{
              ldap: props.employee.ldap,
              slug: props.employeeTeams[0]?.slug ?? 'dropbox-teams',
            }}
            focus={focus}
            setFocus={setFocus}
            selection={selection}
            setSelection={setSelection}
            expanded={expanded}
            setExpanded={setExpanded}
            onClick={(ldap) => navigate(`/people/${ldap}`)}
          />
        )}
        <Stack className={atoms({opacity: expanded ? 0 : 1})} gap="24">
          {isOrgChartEnabled && peers.length > 0 && (
            <ProfileEmployees
              sectionTitle={t('peers')}
              employees={peers}
              employee={props.employee}
              source="peers"
            />
          )}
          {isOrgChartEnabled && (
            <>
              <ProfileProjects employee={props.employee} />
              <ProfileGoals employee={props.employee} />
            </>
          )}
          {!isOrgChartEnabled && peers.length > 0 && (
            <ProfileEmployees
              sectionTitle={t('peers')}
              employees={peers}
              employee={props.employee}
              source="peers"
            />
          )}
          <ProfileWorkingWithMe employee={props.employee} workingWithMe={props.workingWithMe} />
          <ProfileBadges ldap={user} />
        </Stack>
      </Stack>
    </Layout.Container>
  );
};
