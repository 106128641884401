import {Stack} from '@dropbox/dig-foundations';
import {PulseWithGrouping} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useLoaderData, useNavigate} from 'react-router-dom';

import {EmptyPulse} from './EmptyPulse';
import {PulseHeader} from './PulseHeader';
import {PulseQuestionBox} from './PulseQuestionBox';
import {toPulseGroup} from './utils';

export const PulseView = () => {
  const response = useLoaderData() as PulseWithGrouping;
  const grouping = response ? toPulseGroup(response) : {name: ''};
  const navigate = useNavigate();
  useDocumentTitle(`${grouping.name} ${t('pulse')}`);

  if (!response.responses_count) {
    navigate('/pulse');
  }

  return (
    <Layout.Container size="large">
      <Stack gap="16">
        <PulseHeader
          pulse={response.pulse ?? undefined}
          grouping={response}
          employeeCount={response.employee_count}
          responsesCount={response.responses_count ?? undefined}
          pillar={response.pillar}
          selected={grouping.name}
          inProgress={response.in_progress}
          previousId={response.previous_id ?? undefined}
          nextId={response.next_id ?? undefined}
        />
        {response.in_progress || !response.pulse ? (
          <EmptyPulse inProgress={response.in_progress} />
        ) : (
          response.pulse?.questions.map((question, index) => (
            <PulseQuestionBox
              key={index}
              index={index}
              pillar={response.pillar}
              question={question}
              grouping={response}
              responseCounts={response.response_counts}
              metrics={response.metrics ?? undefined}
            />
          ))
        )}
      </Stack>
    </Layout.Container>
  );
};
