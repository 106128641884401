import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine} from '@dropbox/dig-icons/assets';
import {PuzzlePiecesMini} from '@dropbox/dig-illustrations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Layout} from 'components/DSYS/Layout';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useEffect} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import {GoalsJoinTableView} from './GoalsJoinTableView';
import styles from './GoalsJoinView.module.css';

export const GoalsJoinView = () => {
  const {t} = useTranslation();
  const location = useLocation();

  const [delegateId, setDelegateId] = React.useState<string | undefined>();
  const [delegateLdap, setDelegateLdap] = React.useState<string | undefined>();

  useDocumentTitle(`${t('join_an_existing_goal')}`);

  useEffect(() => {
    if (location.state?.source) {
      analyticsLogger().logEvent('GOAL_JOIN_VIEW', {source: location.state.source});
    }
  }, [location.state?.source]);

  return (
    <Layout.Container size="large">
      <Stack gap="24">
        <Header
          delegateId={delegateId}
          setDelegateId={setDelegateId}
          setDelegateLdap={setDelegateLdap}
        />
        <GoalsJoinTableView delegateLdap={delegateLdap} delegateId={delegateId} />
      </Stack>
    </Layout.Container>
  );
};

const Header = ({
  delegateId,
  setDelegateId,
  setDelegateLdap,
}: {
  delegateId?: string;
  setDelegateId: (userId?: string) => void;
  setDelegateLdap?: (ldap?: string) => void;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Box style={{position: 'relative'}}>
      <Box as="div" style={{position: 'absolute', left: '-40px'}}>
        <IconButton
          variant="borderless"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Box as={UIIcon} src={ArrowLeftLine} />
        </IconButton>
      </Box>
      <Box display="flex">
        <Box display="flex" style={{gap: '8px'}} alignItems="center">
          <Box display="flex" style={{gap: '8px'}} alignItems="center">
            <Box className={styles.puzzlePieces} style={{height: '36px', width: '36px'}}>
              <PuzzlePiecesMini altText="puzzlePiece" width={36} height={36} />
            </Box>
            <Box as={Text} size="large" isBold variant="label" display="block">
              {t('join_an_existing_goal')}
            </Box>
          </Box>
        </Box>
        <DelegateSelectMenu
          disabled={false}
          delegateId={delegateId}
          setDelegateId={setDelegateId}
          setDelegateLdap={setDelegateLdap}
        />
      </Box>
    </Box>
  );
};
